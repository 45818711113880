import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/b8k4WWsNQ4o">
    <SEO title="Light to Lights - Counter Culture" />
  </Layout>
)

export default SermonPost
